import React from "react"

export const Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 185 208.4">
      <path
        fill="#00cf5d"
        d="M176.6 46L100.9 2.2c-5.2-3-11.6-3-16.7 0L8.4 46C3.2 49 0 54.5 0 60.5V148c0 6 3.2 11.5 8.4 14.5l75.8 43.7c5.2 3 11.6 3 16.7 0l75.8-43.7c5.2-3 8.4-8.5 8.4-14.5V60.5c-.1-6-3.3-11.5-8.5-14.5zm-51.2 19.1c-.3.9-.6 1.7-1.1 2.4l-46.4 65.3h41.3c3.1 0 5.7 2.5 5.7 5.7s-2.5 5.7-5.7 5.7H65c-3 0-5.5-2.5-5.5-5.5 0-.8.1-1.5.4-2.1.2-.7.6-1.3 1-1.9l46.6-65.5H67.7c-3.1 0-5.7-2.5-5.7-5.7s2.5-5.7 5.7-5.7h52.7c2.9 0 5.2 2.3 5.2 5.2.1.7 0 1.4-.2 2.1z"
      />
    </svg>
  )
}
