export const languages = [
  {
    id: "Bengali",
    text: "ধন্যবাদ",
    progress: 99,
  },
  {
    id: "English",
    text: "Thanks",
    progress: 75,
  },
  {
    id: "Urdu",
    text: "شکریہ",
    progress: 50,
  },
  {
    id: "Hindi",
    text: "धन्यवाद",
    progress: 50,
  },
  {
    id: "Punjabi",
    text: "ਧੰਨਵਾਦ",
    progress: 20,
  },
]
